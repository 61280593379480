"use client";

import { usePathname } from "next/navigation";
import * as React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

import { RavenClient, captureError } from "../../sentry/client";
import { getCustomerTagId } from "../sections/public/basket/meta/getCustomerTagId.client";
import ButtonCopy from "./ButtonCopy";
import { ErrorMessage } from "./Message";

export function ErrorBoundary({
  children,
  code,
  header,
  body,
  text,
  fallback,
}: {
  children: React.ReactNode;
  code?: string;
  header?: React.ReactNode;
  body?: React.ReactNode;
  text?: React.ReactNode;
  fallback?: React.ReactElement;
}) {
  return (
    <ReactErrorBoundary
      fallbackRender={(error) =>
        React.cloneElement(fallback ?? <CommonError />, {
          code: code,
          error: error,
          header: header,
          body: body,
          text: text,
        })
      }
      onError={(error) => {
        console.error("Error caught.", code, error);
        RavenClient.captureBreadcrumb({ code });
        captureError(error);
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
}

export function CommonError({
  code,
  error,
  header,
  body,
  text,
}: {
  code?: string;
  error?: unknown;
  header?: React.ReactNode;
  body?: React.ReactNode;
  text?: React.ReactNode;
}) {
  return (
    <ErrorMessage
      header={header ?? "Something went wrong."}
      body={
        body ?? (
          <>
            {text ?? (
              <>
                An error occurred, and unfortunately we can't provide any more
                information. Please try refreshing the page or coming back
                later.
              </>
            )}
            <br />
            <br />
            <ErrorCodeSection code={code} error={error} />
          </>
        )
      }
    />
  );
}

function ErrorCodeSection({ code, error }: { code?: string; error?: unknown }) {
  const pathname = usePathname() ?? "unknown";
  const customerTag = getCustomerTag();
  const stringifiedError = stringifyError(code, pathname, customerTag, error);

  return (
    <>
      <ButtonCopy text={stringifiedError}>
        Click to copy this error code for support
      </ButtonCopy>
      <code>{stringifiedError}</code>
      <NotifyError
        customerTag={customerTag}
        stringifiedError={stringifiedError}
      />
    </>
  );
}

function NotifyError({
  customerTag,
  stringifiedError,
}: {
  customerTag: string;
  stringifiedError: string;
}) {
  React.useEffect(() => {
    fetch("/api/browsererror", {
      method: "POST",
      body: JSON.stringify({ customerTag, data: stringifiedError }),
    }).catch((e) => {});
  }, [stringifiedError]);
  return null;
}

function getCustomerTag() {
  try {
    return getCustomerTagId();
  } catch {
    return "";
  }
}

function stringifyError(
  code: string | undefined,
  pathname: string,
  customerTag: string,
  error: unknown | undefined
) {
  const now = new Date().toISOString();

  try {
    return JSON.stringify({
      code: code ?? "unknown",
      pathname: pathname,
      error,
      now,
      customerTag,
    });
  } catch (e) {
    return JSON.stringify({
      code: code ?? "unknown",
      pathname: pathname,
      error: "failed_stringify",
      now,
      customerTag,
    });
  }
}

// export class ErrorBoundary extends React.Component<{
//   fallback: React.ReactNode;
//   children: React.ReactNode;
// }> {
//   state = { hasError: false };

//   static defaultProps = {
//     fallback: () => (
// <ErrorMessage
//   header={"Something went wrong."}
//   body={
//     "An error occurred, and we can't provide any more information. Try refreshing the page or coming back later."
//   }
// />
//     ),
//   };

//   componentDidCatch(error) {
// this.setState({ hasError: true });
// console.log("Error caught.", error);
// captureError(new Error(error));
//   }

//   render() {
//     if (this.state.hasError) {
//       return this.props.fallback(this.props);
//     } else {
//       return this.props.children;
//     }
//   }
// }
