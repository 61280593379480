"use client";

import { parseCookies, setCookie } from "nookies";

import { CUSTOMER_TAG_ID_COOKIE_NAME } from "../../Checkout/basketMachine/cookies";

const { v7 } = require("uuid");

export function getCustomerTagId(): string {
  const cookies = parseCookies();
  let customerTagId = cookies[CUSTOMER_TAG_ID_COOKIE_NAME];

  if (!customerTagId || customerTagId === "") {
    customerTagId = v7();
    setCookie(null, CUSTOMER_TAG_ID_COOKIE_NAME, customerTagId, {
      maxAge: 10 * 365 * 24 * 60 * 60,
      path: "/",
      sameSite: "none",
      secure: true,
    });
  }

  return customerTagId;
}
